import React from 'react';
import Seo from './Seo';
import '../assets/sass/grayscale.scss';

export default ({ children }) => (
  <>
    <Seo />
    <div className="page-top">
      {children}
    </div>
  </>
)
