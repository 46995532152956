import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Scroll from './Scroll';
import Img from 'gatsby-image';

export default () => {

  const headerData = useStaticQuery(graphql`
    query HeaderDataQuery {
      file(relativePath: {eq: "bg-header.jpg"}) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      site {
        siteMetadata {
          heading
          subHeading
        }
      }
    }
  `);

  const headerImage = headerData.file;

  const { subHeading, heading } = headerData.site.siteMetadata;

  return (
    <header className="masthead">
      <Img
        className="img-fluid header-image"
        alt="Mountains in the background"
        imgStyle={{ objectPosition: 'center' }}
        sizes={headerImage.childImageSharp.sizes} />
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {subHeading}
          </h2>
          <Scroll offset={-150} type="id" element="projects">
            <a href="#projects" className="btn btn-primary">
              Projects
            </a>
          </Scroll>
        </div>
      </div>
    </header>
  )
}